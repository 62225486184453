import { BLOG_POST_TAGS } from 'constants/blog-post-tags';
import { PATHS } from 'constants/paths';
import ProptechSvg from 'svgs/blog/proptech.svg';

export const LATEST_RELATED_ARTICLES_SETTINGS = {
    hideTitle: true,
    tags: [BLOG_POST_TAGS.PROPTECH],
    button: {
        to: `${PATHS.BLOG}/${PATHS.PROPTECH}`,
        label: 'view-prop-tech-real-estate.footer.button',
    },
    categoryBanner: {
        title: 'view-prop-tech-real-estate.footer.title',
        description: 'view-prop-tech-real-estate.footer.description',
        svg: ProptechSvg,
    },
};

export const ADVANTAGES = [
    {
        index: 1,
        section: 'enhanced-decision-making',
        paragraphsCount: 1,
        links: [
            {
                key: 'link',
                url: `${PATHS.CASE_STUDIES}/${PATHS.SALES_FORECASTING_SOFTWARE}`,
                component: 'gatsby-link',
            },
        ],
    },
    {
        index: 2,
        section: 'operational-efficiency',
        paragraphsCount: 1,
        links: [
            {
                key: 'zoneLink',
                url: `${PATHS.CASE_STUDIES}/${PATHS.COMMERCIAL_REAL_ESTATE_SOFTWARE_ZONE}`,
                component: 'gatsby-link',
            },
            {
                key: 'turoLink',
                url: `${PATHS.CASE_STUDIES}/${PATHS.CONSTRUCTION_MANAGEMENT_SOFTWARE_TURO}`,
                component: 'gatsby-link',
            },
        ],
    },
    {
        index: 3,
        section: 'personalized-customer-experiences',
        paragraphsCount: 1,
    },
    {
        index: 4,
        section: 'predictive-analytics-for-maintenance',
        paragraphsCount: 1,
    },
];

export const ADVANTAGES_FOOTER_LINKS = [
    {
        key: 'link',
        url: PATHS.CONTACT_US,
        component: 'gatsby-link',
    },
];
