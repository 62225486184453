import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { Advantages } from 'modules/objective-advantages-section/components/advantages';
import { arrayOf, number, shape, string } from 'prop-types';
import { useLinkChunks } from 'hooks/use-link-chunks';
import { useTranslatedTitle } from 'hooks/use-translated-title';

const SContainer = styled(Container)`
    content-visibility: auto;
    contain-intrinsic-size: 2000px;
`;

const SSubContainer = styled.div`
    display: flex;
    flex: 1;
    gap: 2rem;
    margin-bottom: ${({ margin }) => (margin ? '5rem' : 0)};

    ${CONSTANTS.MEDIA.max768`
        flex-direction: column;
    `};
`;

const SHeaderSecond = styled(HeaderSecond)`
    flex: 1;
    font-size: 3.7rem;
`;

const SSubTitleWrapper = styled.div`
    flex: 1.5;
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
    justify-content: ${({ bottomContent }) =>
        bottomContent ? 'flex-end' : 'flex-start'};

    ${CONSTANTS.MEDIA.max768`
        margin-top: 3.25rem;
    `};
`;

const SSubTitle = styled(Paragraph)`
    font-weight: bold;
    font-size: 1.375rem;
`;

const SReasonsHeader = styled(HeaderSecond)`
    margin-top: 5.625rem;
    margin-bottom: 3.5rem;
`;

const SFooter = styled.div`
    margin-top: 5rem;
`;

export const ObjectiveAdvantagesSection = ({
    id,
    data,
    title,
    subTitle,
    rootPath,
    subTitleDescription,
    reasonsTitle,
    footer,
    footerLinks,
}) => {
    const { formatMessage } = useIntl();
    const footerLinkChunks = useLinkChunks(footerLinks);
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <SContainer id={id}>
            <SSubContainer margin={!reasonsTitle}>
                <SHeaderSecond id={kebabCaseId} handleCopyUrl={handleCopyUrl}>
                    <FormattedMessage id={title} />
                </SHeaderSecond>
                <SSubTitleWrapper bottomContent={!subTitleDescription}>
                    <SSubTitle>
                        <FormattedMessage id={subTitle} />
                    </SSubTitle>
                    {subTitleDescription && (
                        <Paragraph>
                            <FormattedMessage id={subTitleDescription} />
                        </Paragraph>
                    )}
                </SSubTitleWrapper>
            </SSubContainer>
            {reasonsTitle && (
                <SReasonsHeader>
                    <FormattedMessage id={reasonsTitle} />
                </SReasonsHeader>
            )}
            <Advantages data={data} rootPath={rootPath} />
            {footer && (
                <SFooter>
                    <Paragraph>
                        {formatMessage({ id: footer }, footerLinkChunks)}
                    </Paragraph>
                </SFooter>
            )}
        </SContainer>
    );
};

ObjectiveAdvantagesSection.propTypes = {
    id: string.isRequired,
    data: arrayOf(
        shape({
            index: number.isRequired,
            section: string.isRequired,
            paragraphsCount: number.isRequired,
        }),
    ).isRequired,
    title: string.isRequired,
    subTitle: string.isRequired,
    rootPath: string.isRequired,
    subTitleDescription: string,
    reasonsTitle: string,
};

ObjectiveAdvantagesSection.defaultProps = {
    subTitleDescription: '',
    reasonsTitle: '',
};
