import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { HeaderThird } from 'components/header-third';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { number, string } from 'prop-types';
import { useLinkChunks } from 'hooks/use-link-chunks';

const SColumnItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const SColumnItemHeader = styled(HeaderThird)`
    font-size: 1.75rem;
    position: relative;

    ${CONSTANTS.MEDIA.max420`
        font-size: 1.5rem;
    `};
`;

const SColumnItemHeaderPseudo = styled.span`
    z-index: -1;
    position: absolute;
    inset: -4rem 0 0 -2.5rem;
    color: var(--gray-color-90);
    font-weight: bolder;
    font-size: 7rem;
    width: 100%;

    ${CONSTANTS.MEDIA.max768`
        inset: -4rem 0;
    `};
`;

const SLi = styled.li`
    margin-left: 1rem;
    ::marker {
        color: var(--primary-color);
    }
`;

export const AdvantageItem = ({
    index,
    rootPath,
    section,
    links,
    paragraphsCount,
    ulIndex,
    ulItemsCount,
}) => {
    const { formatMessage } = useIntl();
    const linkChunks = useLinkChunks(links);

    const renderParagraphs = () =>
        Array.from({ length: paragraphsCount }, (_, i) => {
            if (ulIndex === i) {
                const ulItems = Array.from({ length: ulItemsCount }, (_, j) => (
                    <SLi key={`${i}-${j}`}>
                        <Paragraph>
                            {formatMessage(
                                {
                                    id: `${rootPath}.${section}.paragraphs.${i}.${j}`,
                                },
                                linkChunks,
                            )}
                        </Paragraph>
                    </SLi>
                ));
                return <ul key={i}>{ulItems}</ul>;
            }

            return (
                <Paragraph key={i}>
                    {formatMessage(
                        {
                            id: `${rootPath}.${section}.paragraphs.${i}`,
                        },
                        linkChunks,
                    )}
                </Paragraph>
            );
        });

    return (
        <SColumnItemContainer>
            <SColumnItemHeader>
                <FormattedMessage id={`${rootPath}.${section}.title`} />
                <SColumnItemHeaderPseudo>0{index}</SColumnItemHeaderPseudo>
            </SColumnItemHeader>
            {renderParagraphs()}
        </SColumnItemContainer>
    );
};

AdvantageItem.propTypes = {
    data: {
        index: number.isRequired,
        rootPath: string.isRequired,
        section: string.isRequired,
        paragraphsCount: number.isRequired,
        ulIndex: number,
        ulItemsCount: number,
    },
};

AdvantageItem.defaultProps = {
    ulIndex: undefined,
    ulItemsCount: undefined,
};
